<template>
    <div class="reset-password-page house-image" style="min-height: 100vh;">
        <div class="card" style="background-color: transparent; border: 0;">
            <div class="card-body py-0">
                <div class="card-title mb-3 title-bg-image">
                    <div class="logo-image">
                    </div>
                </div>
                <Form class="w-100" @submit="reset_password()" :validation-schema="schema">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-header h3 text-primary" style="background-color: transparent; border: 0;">
                            臺中購物節店家平台店家重設密碼
                        </div>
                        <div class="card-body form-reset-password">
                            <div class="input-group">
                                <div class="form-floating">
                                    <Field name="password" v-slot="{ meta, field }" v-model="reset_password_form.password">
                                        <input
                                            id="password"
                                            v-bind="field"
                                            class="form-control"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            :type="pwd_type"
                                            placeholder="密碼"
                                            autocomplete="on"
                                        />
                                    </Field>
                                    <label for="password">密碼</label>
                                </div>
                                <button type="button" class="input-group-text btn-primary btn" v-on:click="show_pwd()">
                                    <i class="bi bi-eye-fill"></i>
                                </button>
                            </div>
                            <div id="password_help" class="form-text text-start mb-3">8-20碼，包含大寫、小寫英文及數字</div>

                            <div class="input-group">
                                <div class="form-floating">
                                    <Field name="password_confirmation" v-slot="{ meta, field }" v-model="reset_password_form.password_confirmation">
                                        <input
                                            id="password_confirmation"
                                            v-bind="field"
                                            class="form-control"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            :type="pwd_confirm_type"
                                            placeholder="確認密碼"
                                            autocomplete="on"
                                        />
                                    </Field>
                                    <label for="password_confirmation">確認密碼</label>
                                </div>
                                <button type="button" class="input-group-text btn-primary btn" v-on:click="show_pwd_confirm()">
                                    <i class="bi bi-eye-fill"></i>
                                </button>
                            </div>
                            <div id="password_confirmation_help" class="form-text text-start mb-3">請再次輸入密碼</div>

                            <div class="row mb-3" style="padding: 0px 12px 0px 12px;">
                                <div class="px-0 col-6">
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <Field name="captcha" v-slot="{ meta, field }" v-model="reset_password_form.captcha">
                                                <input
                                                    id="floatingCaptcha"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                    type="captcha"
                                                    placeholder="驗證碼"
                                                />
                                            </Field>
                                            <label for="floatingCaptcha">驗證碼</label>
                                        </div>
                                        <button type="button" class="input-group-text btn-primary btn" v-on:click="get_captcha()">
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="px-0 col-6">
                                    <a @click="get_captcha" style="cursor: pointer;">
                                        <img :src="captcha_image" class="captcha-img">
                                    </a>
                                </div>
                            </div>
                        
                            <button class="w-100 btn btn-lg btn-primary mb-3" type="submit">送出</button>

                            <div class="alert alert-success mb-3" role="alert" v-if="success_flag" style="white-space: pre-wrap;">
                                {{ success_message }}
                            </div>
                            <div class="alert alert-danger mb-3" role="alert" v-if="error_flag" style="white-space: pre-wrap;">
                                {{ error_message }}
                            </div>

                            <div class="mt-3 mb-3">
                                <strong>
                                    <span><router-link style="text-decoration: none;" to="/Login">返回登入頁</router-link></span>
                                </strong>
                            </div>
                        </div>
                        <BeforeLoginFooter/>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import { reset_password } from '../../api/auth';
import { captcha } from '../../api/captcha';
import { Field, Form } from 'vee-validate';
import { reset_password_rules } from '../../rules/user/reset_password';
import BeforeLoginFooter from "../../components/BeforeLoginFooter.vue";

export default {
    components: {
        Field,
        Form,
        BeforeLoginFooter,
    },
    data() {
        return {
            reset_password_form: {
                name: "",
                password: "",
                password_confirmation: "",
                captcha: null,
                captcha_key: null,
            },
            success_message: "",
            success_flag: false,
            error_message: "",
            error_flag: false,
            captcha_image: "",
            pwd_type: "password",
            pwd_confirm_type: "password",
        }
    },
    methods: {
        reset_password() {
            this.error_flag = false;
            this.error_message = "",
            this.success_flag = false;
            this.success_message = "",
            reset_password(this.reset_password_form, this.$route.query.verify_code).then(response => {
                this.error_flag = false;
                this.error_message = "",
                this.success_message = response.data.message;
                this.success_flag = true;
            }, error => {
                this.success_flag = false;
                this.success_message = "",
                this.error_message = error.response.data.message;
                this.error_flag = true;
                this.get_captcha();
            });
        },
        get_captcha() {
            captcha().then(response => {
                this.captcha_image = response.data.img;
                this.reset_password_form.captcha_key = response.data.key;
            })
        },
        show_pwd() {
            if (this.pwd_type === 'password') {
                this.pwd_type = 'text';
            } else {
                this.pwd_type = 'password';
            }
        },
        show_pwd_confirm() {
            if (this.pwd_confirm_type === 'password') {
                this.pwd_confirm_type = 'text';
            } else {
                this.pwd_confirm_type = 'password';
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.get_captcha();
    },
    computed: {
        schema() {
            return reset_password_rules;
        },
    }
};
</script>
